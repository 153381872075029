import { createWebHistory, createRouter } from "vue-router";

//app.use(VueRouter)

// eslint-disable-next-line no-unused-vars
const routes = [
  {
    path: '/error/forbidden',
    name: 'error_forbidden',
    component: () => import(/* webpackChunkName: "error_forbidden" */ "../views/error/Forbidden.vue"),
  },
  {
    path: "/",
    name: "public",
    component: () => import(/* webpackChunkName: "home" */ "../views/Decorator.vue"),
    meta: {
      allowedRoles: ['ROLE_DOMOTIC']
    },
    children: [
      {
        path: '/socket-test',
        component: () => import(/* webpackChunkName: "home" */ "../views/SocketTest.vue"),
        meta: {
          allowedRoles: ['ROLE_DOMOTIC']
        }
      },
      {
        path: '/energymonitor/realtime',
        alias: ["/home"],
        component: () => import(/* webpackChunkName: "home" */ "../views/energymonitor/Realtime.vue"),
        meta: {
          allowedRoles: ['ROLE_DOMOTIC']
        }
      },
      {
        path: '/thmonitor/realtime',
        alias: ["/home"],
        component: () => import(/* webpackChunkName: "home" */ "../views/thmonitor/Realtime.vue"),
        meta: {
          allowedRoles: ['ROLE_DOMOTIC']
        }
      },
      {
        path: "/switches",
        name: "switches",
        component: () => import(/* webpackChunkName: "home" */ "../views/Switches.vue"),
        meta: {
          allowedRoles: ['ROLE_DOMOTIC']
        },
        
      },
      {
        path: "/energymonitor/consumption/chart",
        name: "device_consumption_chart",
        component: () => import(/* webpackChunkName: "home" */ "../views/energymonitor/DeviceConsumptionChart.vue"),
        meta: {
          allowedRoles: ['ROLE_DOMOTIC']
        },
      },
      {
        path: "/thmonitor/chart",
        name: "device_th_chart",
        component: () => import(/* webpackChunkName: "home" */ "../views/thmonitor/THChart.vue"),
        meta: {
          allowedRoles: ['ROLE_DOMOTIC']
        },
      },
      {
        path: "/scheduler/routines",
        name: "routines",
        component: () => import(/* webpackChunkName: "home" */ "../views/scheduler/Routines.vue"),
        meta: {
          allowedRoles: ['ROLE_ADMINISTRATOR']
        },
      },
      {
        path: "/scheduler/routines/new",
        name: "new_routine",
        component: () => import(/* webpackChunkName: "home" */ "../views/scheduler/Routine.vue"),
        meta: {
          allowedRoles: ['ROLE_ADMINISTRATOR']
        },
      },
      {
        path: "/scheduler/routines/:jobGroup/:jobName",
        name: "edit_routine",
        component: () => import(/* webpackChunkName: "home" */ "../views/scheduler/Routine.vue"),
        meta: {
          allowedRoles: ['ROLE_ADMINISTRATOR']
        },
      },
    ]    
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log('scrollBeahavior',to, from, savedPosition);
    if (!to.meta.keepScroll)
      return {top: 0, left:0}
    if (savedPosition)
      return savedPosition;
  }
});

export default router;
