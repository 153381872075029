import Keycloak from "keycloak-js";

export default {
    install: (app, options) => {
        console.log(process.env.VUE_APP_AUTH_BASE_URL);
        console.log(process.env.VUE_APP_AUTH_REALM);
        console.log(process.env.VUE_APP_AUTH_CLIENT_ID);

        const keycloak = new Keycloak({url: process.env.VUE_APP_AUTH_BASE_URL, realm: process.env.VUE_APP_AUTH_REALM, clientId: process.env.VUE_APP_AUTH_CLIENT_ID});
        
        const authenticated = new Promise((resolve, reject) => {
                keycloak
                .init({ 
                    onLoad: "login-required",
                    //silentCheckSsoRedirectUri:  window.location.origin + '/',
                    //timeSkew: 0,
                    pkceMethod: 'S256',
                    token: localStorage.getItem('access_token'),
                    idToken: localStorage.getItem('id_token'),
                    refreshToken: localStorage.getItem('refresh_token'),
                })
                .then((authenticated) => {
                    console.log('AUTHENTICATED', authenticated);
                    if (authenticated) {
                        //localStorage.setItem('refresh_token', keycloak.refreshToken)
                        console.log('Autenticato!!!!!!!!!!!!!!!!!!!')
                        
                    } else {
                        console.log('Non autenticato!!!!!!!!!!!!!!!!!!!')
                    }
                    resolve(authenticated)
                })
                .catch((e) => {
                    console.log(e);
                    console.log(`keycloak init exception: ${e}`);
                    reject
                })
            })
        
        keycloak.onAuthRefreshSuccess = () => {
            localStorage.setItem('refresh_token', keycloak.refreshToken);
            localStorage.setItem('id_token', keycloak.idToken);
            localStorage.setItem('access_token', keycloak.token)
        }
        keycloak.onAuthSuccess = () => {
            localStorage.setItem('refresh_token', keycloak.refreshToken);
            localStorage.setItem('id_token', keycloak.idToken);
            localStorage.setItem('access_token', keycloak.token)
        }

        keycloak.hasAnyRoles = async (roles) => {
            console.log('-----------> ', await authenticated);
            if (!keycloak.authenticated)
              return false;
            console.log(keycloak)
            if (roles) {
                for (let i = 0; i < roles.length; i++) {
                    const role = roles[i];
                    if (role === "ROLE_AUTHENTICATED") return true;
                    const found = keycloak.hasRealmRole(role);
                    if (found)
                        return true;
                }
            }
            console.log('hasAnyRoles FALSE', roles);
            return false;
        }

        app.config.globalProperties.$auth = keycloak
        console.log('Plugin auth installed', options)
    }
  }