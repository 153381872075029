import {createApp} from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import mitt from "mitt";
import router from "./router";
import auth from "@/plugins/auth.js"
import axios from "@/plugins/axios.js"
//import wsClient from "@/plugins/websocket-client.js"
//import socketio from 'socket.io';
//import VueSocketIO from 'vue-socket.io';

import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import TextArea from 'primevue/textarea';
import Password from 'primevue/password';
import AutoComplete from 'primevue/autocomplete';
import Fieldset from 'primevue/fieldset';
import Dropdown from 'primevue/dropdown';
import Chip from 'primevue/chip';
import Badge from 'primevue/badge';
import Tooltip from 'primevue/tooltip';
import Card from 'primevue/card';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Menubar from 'primevue/menubar';
import Carousel from 'primevue/carousel';
import Galleria from 'primevue/galleria';
import DataView from 'primevue/dataview';
import Image from 'primevue/image';
import Breadcrumb from 'primevue/breadcrumb';
import FileUpload from 'primevue/fileupload';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import InputSwitch from 'primevue/inputswitch';
import Tree from 'primevue/tree';
import Sidebar from 'primevue/sidebar';
import ConfirmDialog from 'primevue/confirmdialog';
import TabMenu from 'primevue/tabmenu';
import Chart from 'primevue/chart';
import SplitButton from 'primevue/splitbutton';
import MenuBar from 'primevue/menubar';
import BlockUI from 'primevue/blockui';

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'primeflex/primeflex.css';
import '@/assets/styles.css'

import cronLight from '@vue-js-cron/light'
import '@vue-js-cron/light/dist/light.css'

const app = createApp(App);

app.config.globalProperties.$eventBus = new mitt();

app.use(auth, {})
app.use(axios, {})
//app.use(wsClient, {})
app.use(cronLight)

//export const SocketInstance = socketio(process.env.VUE_APP_BASEAPIURL);

//app.use(VueSocketIO, SocketInstance);


app.use(PrimeVue, {
    locale: {
      firstDayOfWeek: 1,
      dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
      dayNamesMin: ["Do","Lu","Ma","Me","Gi","Ve","Sa"],
      monthNames: [ "Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre" ],
      monthNamesShort: [ "Gen", "Feb", "Mar", "Apr", "Mag", "Giu","Lug", "Ago", "Set", "Ott", "Nov", "Dic" ],
      today: 'Oggi',
      clear: 'Pulisci',
      dateFormat: 'dd/mm/yy',
      weekHeader: 'Set',
  
      accept: 'Sì',
      reject: 'No',
    }
  });


app.use(router);
app.use(ToastService);

app.component("p-dialog", Dialog);
app.component("p-button", Button);
app.component("p-calendar", Calendar);
app.component("p-inputtext", InputText);
app.component("p-textarea", TextArea);
app.component("p-inputnumber", InputNumber);
app.component("p-checkbox", Checkbox);
app.component("p-radiobutton", RadioButton);
app.component("p-password", Password);
app.component('p-inputswitch', InputSwitch);
app.component("p-autocomplete", AutoComplete);
app.component("p-fieldset", Fieldset);
app.component('p-toast', Toast);
app.component('p-dropdown', Dropdown);
app.component('p-chip', Chip);
app.component('p-badge', Badge);
app.component('p-card', Card);
app.component('p-tristatecheckbox',TriStateCheckbox);
app.component('p-datatable', DataTable);
app.component('p-column', Column);
app.component('p-menubar', Menubar);
app.component('p-carousel', Carousel);
app.component('p-galleria', Galleria);
app.component('p-dataview', DataView);
app.component('p-image', Image);
app.component('p-breadcrumb', Breadcrumb);
app.component('p-fileupload', FileUpload);
app.component('p-tabview', TabView);
app.component('p-tabpanel', TabPanel);
app.component('p-accordion', Accordion);
app.component('p-accordiontab', AccordionTab);
app.component('p-tree', Tree);
app.component('p-sidebar', Sidebar);
app.component('p-confirmdialog', ConfirmDialog);
app.component('p-tabmenu', TabMenu);
app.component('p-chart', Chart);
app.component('p-splitbutton', SplitButton);
app.component('p-menubar', MenuBar);
app.component('p-blockui', BlockUI);

app.directive('tooltip', Tooltip);

router.beforeEach((to, from, next) => {
  console.log('BELLO ROUTER');
  if (to.path == "/token") {
    app.config.globalProperties.$apiClient
      .loginWithToken(to.query?.access_token)
      .then(() => {
        next({
          path: to.query?.redirect,
          replace: true,
        });
      })
      .catch((error) => {
        console.log(error);
        next({
          path: "/login",
          query: { redirect: to.fullPath },
          replace: true,
        });
      });
  } else {
    if (to.meta?.allowedRoles) {
      app.config.globalProperties.$auth.hasAnyRoles(to.meta.allowedRoles)
        .then(ok => {
          console.log("hasAnyRoles?", to.meta.allowedRoles, ok);
          next();
        })
       
          //if (ok)
            
          /*else
            next({
              path: "/error/forbidden",
              //replace: true,
            }); */
      
      
      //if (app.config.globalProperties.$auth.hasAnyRoles(to.meta.roles)) {
        
      /*} else {
        next({
          path: "/error/forbidden",
          //replace: true,
        });
      }*/
      
    } else {
      if (to.meta?.keepAlive && to.path?.startsWith(from.path)) {
        //console.log('---------------------------------------> store position', window.scrollY, window.pageYOffset);
        //sessionStorage.setItem(from.path, JSON.stringify({top: window.scrollY , left: 0}));
      }
      console.log('---> Apertura pagina pubblica...', to.path);
      next();
    }
  }
});



app.mount('#app')