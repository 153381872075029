import Axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

export default {
    install: (app, options) => {
        console.log(options);
        const apiClient = Axios.create({
            baseURL: process.env.VUE_APP_BASEAPIURL,
            headers: {
                "Content-Type": "application/json",
                //'Access-Control-Allow-Origin' : '*'
            },
            //withCredentials: true,
            });
        
        const refreshAuthLogic = async (failedRequest) => {
          console.log('***************************************', failedRequest)
          try {
            console.log('************************************** Token expired', app.config.globalProperties.$auth.token)
            console.log('refreshtoken', app.config.globalProperties.$auth.refreshToken)
            await app.config.globalProperties.$auth.updateToken(3600);
            console.log('************************************** Token refreshed', app.config.globalProperties.$auth.token)
            failedRequest.response.config.headers['Authorization'] = app.config.globalProperties.$auth.token;
            return failedRequest;
          } catch (error) {
            console.log(error)
          }
        }

        createAuthRefreshInterceptor(apiClient, refreshAuthLogic);

        apiClient.interceptors.request.use(
            (config) => {
              //console.info("TOKEN", app.config.globalProperties.$auth.token);
              //const token = localStorage.getItem("access_token");
              if (app.config.globalProperties.$auth.token)
                config.headers["Authorization"] = "Bearer " + app.config.globalProperties.$auth.token;
              return config;
            },
            (error) => {
              console.error("ERR", error);
              return Promise.reject(error);
            }
          );
      
          
        const isoDateFormat = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\d)/;
    
        function isIsoDateString(value){
            return value && typeof value === "string" && isoDateFormat.test(value);
        }
    
        function handleDates(body) {
            if (body === null || body === undefined || typeof body !== "object")
                return body;
        
            for (const key of Object.keys(body)) {
                const value = body[key];
                if (isIsoDateString(value)) body[key] = new Date(value);
                else if (typeof value === "object") handleDates(value);
            }
        }
    
        apiClient.interceptors.response.use(
            function (response) {
                handleDates(response.data);
                return response;
            },
            async function (error) {
                console.log(error);
                if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                }
        
                return Promise.reject(error);
            }
        );
          
        
        apiClient.searchGraphQLAPI = async (operationName, params, fields, first, pageSize) => {
            var query = "";
            var i = 0;
            for (var pair of params.entries()) {
              if (i > 0) query += ";";
              query += pair[0] + "=" + pair[1];
              i++;
            }
        
            var s =
              "{" +
              operationName +
              '(query: "' +
              query +
              '", rowIndex: ' +
              first +
              ", pageSize: " +
              pageSize +
              ") {rowIndex pageSize rowCount elements {" +
              fields +
              "}}}";
            console.log(s);
            var req = {
              query: s,
            };
            const res = await apiClient.post("/graphql", req);
            //console.log(res);
            return res.data[operationName];
          }
        
          apiClient.searchAPI = async (uri, params, first, pageSize) => {
            if (params == null) params = new URLSearchParams();
            if (first != null) params.set("rowIndex", first);
            if (pageSize != null) params.set("pageSize", pageSize);
            const res = await apiClient.get(uri, { params: params });
            return res.data;
          }
        
        
          apiClient.download = async (url, fileName) => {
            const response = await apiClient.get(url, {
              responseType: 'arraybuffer'
            });
            console.log(response);
            console.log('Content-Type', response.headers['content-type']);
            const file = new Blob(
              [response.data], 
              {type: response.headers['content-type']});
            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(link.href);
            return link;
          }

        app.config.globalProperties.$apiClient = apiClient
    }
}