<template>
  
    <router-view v-slot="{ Component }">
        <template v-if="$route.meta.keepAlive">
          <keep-alive >
            <component :is="Component" :key="$route.fullPath" />
          </keep-alive>
        </template>
        <template v-else>
          <component :is="Component" :key="$route.fullPath" />
        </template>
    </router-view>  

  <Loading :active="loading" 
        :can-cancel="false" 
        background-color="#000"
        :opacity="0.4"
        :is-full-page="true"/>

  <p-toast />

</template>

<script>
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';

export default {
  name: 'App',
  components: {Loading},
  data() {
    return {
      loading: false,
      menuItems: [
        {
          label: 'Realtime',
          items: [
            {
              label: 'Energy monitor',
              to: '/energymonitor/realtime'
            },
            {
              label: 'TH monitor',
              to: '/thmonitor/realtime'
            }
          ]
        },
        {
          label: 'Interruttori',
          to: '/switches'
        }
      ]
    }
  }
}
</script>
